import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import CtaBar from '../components/CtaBar';

//import Container from 'react-bulma-components/lib/components/container';

import ExpertFinderInline from '../components/ExpertFinderInline';
import { PageWrap } from '../styles/common';
import Masthead from '../components/Masthead';

export default ({ teamMembers, incomingState }) => {
	return (
		<PageWrap>
			<Helmet>
				<title>Team | Spergel Corporate</title>
			</Helmet>
			<Masthead image={`/img/SpergelCorporate_Team_Masthead-Desktop-2.jpg`} />
			<div className="container" style={{ padding: '1rem' }}>
				<Blurb>
					<h2>We have the expert you're looking for</h2>
					<p>
						Experience that spans more than 30 years is experience that you can
						count on. Our skilled team of Chartered Professional Accountants,
						Licensed Insolvency Trustees, Lawyers, and Certified Fraud
						Examiners, among others, have a lot of experience assisting
						businesses with debt solutions. We have assisted hundreds of
						businesses with the types of debt, cash flow, and operational issues
						your business is facing right now. Every Spergel Licensed Insolvency
						Trustee has the expertise and resources needed to fix your debt
						problems quickly and decisively.
					</p>
				</Blurb>
			</div>
			<ExpertFinderInline
				teamMembers={teamMembers}
				incomingState={incomingState}
			/>
			<CtaBar>
				<h3>
					Let's start a conversation.
					<br />
					<a href="/contact">Contact us today.</a>
				</h3>
			</CtaBar>
		</PageWrap>
	);
};

const Blurb = styled.div`
	h2 {
		font-size: 2rem;
		text-align: center;
		padding: 2rem 0;
	}
`;
