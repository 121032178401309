import React from 'react';
import Layout from '../components/Layout';
import TeamTemplate from '../templates/TeamTemplate';
import { graphql } from 'gatsby';

const TeamPage = ({ data, location }) => {
	const teamMembers = data.allTeamJson.edges.map((edge) => ({
		name: edge.node.name,
		title: edge.node.title,
		round_image: edge.node.round_image,
		slug: edge.node.fields.slug,
		industries: edge.node.industries
			? edge.node.industries.map(({ industry }) => industry)
			: [],
		services: edge.node.services
			? edge.node.services.map(({ service }) => service)
			: [],
	}));
	return (
		<Layout>
			<TeamTemplate teamMembers={teamMembers} incomingState={location.state} />
		</Layout>
	);
};

export default TeamPage;

export const query = graphql`
	query {
		allTeamJson(sort: { fields: [order] }) {
			edges {
				node {
					name
					title
					round_image
					services {
						service
					}
					industries {
						industry
					}
					fields {
						slug
					}
				}
			}
		}
	}
`;
