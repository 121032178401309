import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Select from 'react-select';

//import Container from 'react-bulma-components/lib/components/container';
//import Columns from 'react-bulma-components/lib/components/columns';
import ArrowLink from './ArrowLink';

import common from '../styles/common';

const Wrap = styled.div`
	padding: 4rem 0;

	text-align: center;
	h2 {
		text-align: center;
		margin-bottom: 4rem;
	}
	a {
		text-decoration: none;
		color: ${common.spergelRed};
		font-size: 1rem;
		font-weight: 700;
	}
`;

const TrusteeTile = styled.div`
	display: flex;
	margin: 0;
	@media only screen and (min-width: 768px) {
		margin: 1rem;
	}
`;

const ImageWrap = styled.div`
	flex-basis: 160px;
	img {
		width: 100%;
	}
`;

const ContentWrap = styled.div`
	flex-basis: 200px;
	flex-grow: 1;
	padding: 1rem 0 1rem 2rem;
	a {
		margin-left: 0;
		font-size: 1rem;
	}
	h3 {
		@media only screen and (min-width: 768px) {
			font-size: 2rem;
		}
		font-size: 1.25rem;
		margin: 0;
		font-weight: 300;
	}
	h4 {
		margin: 0;
		font-weight: 300;
		margin-bottom: 1rem;
	}
`;

// react-select style API.
const customStyles = {
	control: (provided) => ({
		...provided,
		width: '100%',
		fontSize: '1.5rem',
		backgroundColor: 'transparent',
		borderRadius: 0,
		border: 'none',
		borderBottom: `2px solid ${common.lightGrey}`,
		paddingBottom: '0.5rem',
	}),
	dropdownIndicator: (provided) => ({
		...provided,
		opacity: 1,
		color: 'black',
	}),
};

export default ({ incomingState, teamMembers }) => {
	const data = useStaticQuery(graphql`
		{
			industries: allIndustriesJson(sort: { fields: [name] }) {
				edges {
					node {
						name
					}
				}
			}
			services: allServicesJson(sort: { fields: [name] }) {
				edges {
					node {
						name
					}
				}
			}
			team: allTeamJson(sort: { fields: [name] }) {
				edges {
					node {
						name
						fields {
							slug
						}
					}
				}
			}
		}
	`);

	const industriesList = data.industries.edges.map(({ node }) => ({
		value: node.name,
		label: node.name,
	}));

	const servicesList = data.services.edges.map(({ node }) => ({
		value: node.name,
		label: node.name,
	}));

	const teamList = data.team.edges.map(({ node }) => ({
		value: node.fields.slug,
		label: node.name,
	}));

	const [service, setService] = useState(
		incomingState ? incomingState.service : null
	);
	const [industry, setIndustry] = useState(
		incomingState ? incomingState.industry : null
	);
	const [trustee, setTrustee] = useState(
		incomingState ? incomingState.trustee : null
	);

	const isMemberShown = (member) => {
		if (!service && !industry && !trustee) {
			return true;
		} else if (member.slug === trustee) {
			return true;
		} else {
			if (service && industry) {
				if (
					member.industries.some((i) => i === industry) &&
					member.services.some((s) => s === service)
				) {
					return true;
				}
			} else if (!service && industry) {
				if (member.industries.some((i) => i === industry)) return true;
			} else if (service && !industry) {
				if (member.services.some((s) => s === service)) return true;
			}
		}
	};

	return (
		<>
			<Wrap>
				<div className="container" style={{ padding: '1rem' }}>
					<div className="columns">
						<div className="column">
							<Select
								defaultValue={
									service ? { label: service, value: service } : null
								}
								isClearable
								options={servicesList}
								styles={customStyles}
								isSearchable={false}
								placeholder={'Service'}
								onChange={(e) => {
									setService(e ? e.value : null);
								}}
							/>
						</div>
						<div className="column">
							<Select
								defaultValue={
									industry ? { label: industry, value: industry } : null
								}
								isClearable
								options={industriesList.filter(
									(industry) => industry !== 'Others'
								)}
								styles={customStyles}
								isSearchable={false}
								placeholder={'Industry'}
								onChange={(e) => {
									setIndustry(e ? e.value : null);
								}}
							/>
						</div>
						<div className="column">
							<Select
								defaultValue={
									trustee ? { label: trustee, value: trustee } : null
								}
								isClearable
								options={teamList}
								styles={customStyles}
								isSearchable={false}
								placeholder={'Trustee'}
								onChange={(e) => {
									setTrustee(e ? e.value : null);
								}}
							/>
						</div>
					</div>
				</div>
			</Wrap>
			<div className="container" style={{ padding: '1rem' }}>
				<div className="columns is-multiline" style={{ marginBottom: '4rem' }}>
					{teamMembers
						.filter((member) => isMemberShown(member))
						.map((member, index) => {
							return (
								<div className="column is-half" key={index}>
									<TrusteeTile>
										<ImageWrap>
											<a href={member.slug}>
												<img src={member.round_image} alt={member.name} />
											</a>
										</ImageWrap>
										<ContentWrap>
											<h3>{member.name}</h3>
											<h4>{member.title}</h4>
											<ArrowLink href={member.slug}>Contact</ArrowLink>
										</ContentWrap>
									</TrusteeTile>
								</div>
							);
						})}
				</div>
			</div>
		</>
	);
};
